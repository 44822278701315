<script setup lang="ts">
import { getSpacingClassesForBlok } from '@/utils/storyblok';
import type { StandaloneHeadlineStoryblok } from '@/storyblok-types';

withDefaults(defineProps<{
  blok: StandaloneHeadlineStoryblok;
}>(), {  })

const alignment = computed(() => `text-${__props.blok.alignment}`);
const spacingClasses = computed(() => getSpacingClassesForBlok(__props.blok));
const headlineSizeClass = computed(() => ({
  large: 'text-4xl md:text-8xl',
  medium: 'text-2xl md:text-6xl',
  small: 'text-xl md:text-4xl',
})[__props.blok.fontSize ?? 'medium']);
const subtitleSizeClass = computed(() => ({
  large: 'text-xl md:text-3xl',
  medium: 'text-lg md:text-2xl',
  small: 'text-base md:text-xl',
})[__props.blok.fontSize ?? 'medium']);
const gapClass = computed(() => ({
  large: 'gap-6',
  medium: 'gap-4',
  small: 'gap-2',
})[__props.blok.fontSize ?? 'medium']);
</script>

<template>
  <div
    v-editable="blok"
    :class="[alignment, spacingClasses]"
    :style="{backgroundColor: blok.backgroundColor.value}"
  >
    <div class="container max-w-5xl">
      <div
        class="flex flex-col"
        :class="[gapClass, {
          'justify-center': alignment === 'center',
          'justify-start': alignment === 'left',
        }]"
      >
        <div
          v-if="blok.showLine"
          class="flex justify-center"
        >
          <svg
            class="w-36 lg:w-44"
            viewBox="0 0 138 22"
            :style="{ color: blok.lineColor?.value }"
          >
            <path
              d="m60.34,22c-1.43,0-2.87-.23-4.17-.86l-.15-.07c-2.51-1.37-3.97-3.83-3.86-6.46-.01-.76.06-1.49.12-2.13.05-.52.1-1.02.09-1.45-.31,0-.61.04-.9.12l-.21.05c-3.71.78-7.17,2.08-10.84,3.45-1.59.6-3.22,1.21-4.88,1.78-10.41,3.56-21.37,5.26-32.61,5.09C1.29,21.49-.03,20.13,0,18.48c.03-1.63,1.36-2.94,2.99-2.94h.05c10.54.18,20.83-1.44,30.58-4.77,1.57-.54,3.17-1.14,4.71-1.72,3.69-1.38,7.5-2.82,11.62-3.69,1.43-.39,2.95-.43,4.41-.11l.46.14c2.03.8,3.37,2.6,3.48,4.62.12,1.1.02,2.13-.06,3.03-.05.55-.1,1.08-.09,1.52v.14s0,.14,0,.14c-.02.31.24.67.68.93,1.2.51,3.59.22,6.16-.75,4.81-1.83,8.67-4.61,12.77-7.56,1.49-1.07,3.03-2.18,4.61-3.24,2.54-1.87,5.6-3.25,8.84-4,1.49-.38,3.09-.28,4.5.31l.31.13.27.19c1.32.93,2.07,2.37,2.04,3.91.09,2-.44,3.88-.91,5.53-.34,1.22-.67,2.38-.69,3.35.28.06.58.08.88.04l.22-.02c8.5-.32,25.59-4.55,35.29-12.64,1.27-1.06,3.15-.89,4.21.38,1.06,1.27.89,3.15-.38,4.21-10.85,9.05-29.17,13.64-38.8,14.02-1.74.17-3.5-.19-4.98-1.02l-.26-.14-.23-.19c-1.24-1.06-1.94-2.56-1.91-4.12-.09-1.99.44-3.85.91-5.5.25-.9.5-1.76.62-2.55-2.37.58-4.59,1.6-6.42,2.96l-.12.09c-1.51,1.01-3.03,2.1-4.49,3.15-4.23,3.04-8.59,6.19-14.14,8.3-1.61.61-4.16,1.39-6.75,1.39Z"
              fill="currentColor"
            />
          </svg>
        </div>

        <component
          :is="blok.subtitleTag"
          v-if="blok.subtitle && blok.subtitlePosition === 'above'"
          class="my-0 font-normal leading-relaxed!"
          :class="[subtitleSizeClass]"
          :style="{ color: blok.subtitleColor.value }"
        >
          <FormattedMarkdown :content="blok.subtitle" />
        </component>

        <component
          :is="blok.titleTag"
          v-if="blok.title"
          class="my-0 whitespace-pre-line text-balance font-black leading-tight!"
          :class="[headlineSizeClass, { 'uppercase': blok.uppercase }]"
          :style="{color: blok.titleColor.value}"
        >
          <FormattedMarkdown :content="blok.title" />
        </component>

        <component
          :is="blok.subtitleTag"
          v-if="blok.subtitle && blok.subtitlePosition === 'below'"
          class="my-0 font-normal leading-relaxed!"
          :class="[subtitleSizeClass]"
          :style="{color: blok.subtitleColor.value}"
        >
          <FormattedMarkdown :content="blok.subtitle" />
        </component>
      </div>
    </div>
  </div>
</template>
